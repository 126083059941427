
import * as React from 'react';
import { Link } from 'react-router-dom';
import Page from './page/page';



function Card(props) {

    const css = {
        // width:"100%",
        color: "white",
        display: "flex-box"
    }

    

    return  <div style={css}>
       <Link to={'/page'} state={{obj:props}}> <img style={{ width: "100%" }} src={props.imagelogo} ></img></Link>
        <span style={{ color: "grey", textDecoration: "outline", fontFamily: "serif" }}>{props.date}</span>
        <h1 style={{ fontSize: "16px", color: "white",textDecoration:"none" }} > {props.moviename}</h1>
        {/* <p style={{ fontSize: "12px" }}>{props.tags}</p> */}
    </div>
}

export default Card;