import React from "react";
import { Helmet } from "react-helmet";
export default function About(){

    return <><Helmet><title> About page | 4khdhub</title><meta name="description" content="official About page of our website this page contain out contact details" />
        <meta name="keywords" content="hd Movies download | 4khdhub movies | bollywood movies download " />
    
    </Helmet>
        <div style={{color:"white"}} >
            <h1>About us</h1>
            <p> Hey Buddy  Welcome to 4kHDHUB</p>
            <ul>
                <li>  yeah ! there are many websites of Downloading But Ads pop-up are the wrost part of websites .</li>
                <li>Our website is Ad free and no Popup Buddy 🥰 .</li>
                <li> if you want your Favourite Movies and Series Just write mail in Support Section your req is done within 24 hr .</li>
                <li> So Keep Download & Keep Watching 😁</li>
            </ul>
        </div>
    </>
}