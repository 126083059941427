import React from "react";
import back from "./img/back.png"
import { useLocation } from 'react-router-dom'
import axios from "axios";
import { Helmet } from "react-helmet";

export default function Page() {
    const [data ,setdata]=React.useState('');
    const location = useLocation()
    const {obj }= location.state;
    // setdata(obj)
    function tgt(e) {
        console.log(obj);
    }
     async function funnn(){
        const url = 'http://localhost:5000/'
        const datasend= await axios.post((url),obj.moviename).then(()=>{
            console.log('sucessfully data is postd');

        });

    }
   
    return <><Helmet><title> {obj.moviename} | 4khdhub </title>
        <meta name="keywords" content="hd Movies download | 4khdhub movies | bollywood movies download " />
    <meta name="description" content={obj.discription} /></Helmet><div style={{ backgroundColor: "#1A120B", textAlign: "center", color: "white",margin:0 }}>
    {/* <button  style={{ backgroundColor: "red",position:"absolute",left:"3%",top:"0.5%", color: "white", fontFamily: "monospace" , borderRadius:"2%",boxShadow:"rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px"}}><img height={"60px"} src={back} /></button> */}
        <h1 style={{ fontSize: "3rem",margin:0}}>{obj.moviename}</h1>
        <img style={{ width: "50%", boxShadow: "rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px" }} src={obj.imagelogo}></img>
        <p>{obj.discription}
        </p>
        <p> {obj.date}</p>
        <hr></hr><div style={{ display: "-ms-grid",grid:"2" }}>
        <h1>Screenshots: (Must See Before Downloading)…</h1>
            <div>
                <img style={{ width: "90%" }} src={obj.s1} />
            </div>
            <div>
                <img style={{ width: "90%" }} src={obj.s2} />
            </div>
            <div>
                <img style={{ width: "90%" }} src={obj.s3} />
            </div>
            <div>
                <img style={{ width: "90%" }} src={obj.s4} />
            </div>


        </div> <hr></hr>
        <div>
            <h1 style={{ color: "white", fontFamily: "cursive" ,boxShadow:"rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px"}} onClick={tgt}> Click here To DOWNLOAD</h1>
            <div style={{ backgroundColor: "lightblue", padding: "3%", display: "-ms-flexbox", borderRadius: "5%" }}>
               <button  style={{width:'100px'}}> <a  onClick={funnn} href={obj.dw} >DOWNLOAD </a></button> 
               

            </div>
            <hr></hr>
            <p>
            Winding Up ❤️</p>
<p>Thank You For Visiting 4khdhub The Prefect Spot For HD Bollywood Movies & TV Series Download. So Please Keep Downloading & Keep Sharing. Enjoy!
</p>    <hr></hr>
<ul style={{textAlign:"left"}}>
<li>480p EXTENDED IMAX BluRay x264</li>
<li>720p EXTENDED IMAX 10Bit BluRay x264</li>
<li>1080p EXTENDED IMAX 10Bit BluRay x264</li>
<li>Extended.Cut UHD Re-Grade 2160p 4K 10bit HDR x265</li>
</ul>
 <hr></hr>
            
            <hr></hr>
        </div>
    </div></> 
}