import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, redirect } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';

const drawerWidth = 240;


export default function Header(props) {
  const date=new Date();
  const year= date.getFullYear();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);


  // React.useEffect(()=>{query();},[])
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <Box sx={{ flexGrow: 1 , padding: 1, borderRadius: 1, color: 'black',}}>
      <AppBar position="static" >
        <Toolbar sx={{backgroundColor:"#3A4F7A" ,color:"white" }}>
       
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            sx={{ mr: 2,display: { sm:'block',md:'block' ,lg:'block'} }}
          >
            <MenuIcon  />
          </IconButton>
          <Typography
variant="h6"
noWrap
component="div"
sx={{ flexGrow: 3, fontSize:"1rem", display: { xs: '5px', sm: '5px' } ,width:"100%" ,fontFamily:"cursive"}}
>
<Link style={{color:"white",textDecoration:"none",fontSize:"1.8rem"}} to={`/`}>4K HDHUB</Link>
</Typography>
<Link style={{color:"white",textDecoration:"none",fontSize:"1.6rem"}} to={`/search`}>Search</Link>
          
          
              <Box sx={{ ml:2,p:3,marginLeft:'10%',display: { xs: 'none', sm: 'none',md:"none",lg:'none' }}}>
              <Link style={{color:"white",marginRight:'5%'}} to={`/admin`}>Support</Link>
              <Link style={{color:"white",marginRight:'5%'}} to={`/`}>Home</Link> 
              <Link style={{color:"white",marginRight:'5%'}} to={`/about`}>About</Link>
              
              <Link style={{color:"white",marginRight:'5%'}} to={`/bollywoodmovies`}>BollyWood </Link>
              
              <Link style={{color:"white",marginRight:'5%'}} to={`/webseries`}>Web Series</Link>
              
              <Link  style={{color:"white",marginRight:'5%'}} to={`/dualaudio`}>Dual Audio</Link>
              
              <Link style={{color:"white",marginRight:'5%'}} to={`/anime`}>Anime</Link>
          </Box>
                
             
            
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block', md:'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth,backgroundColor:"#3A4F7A",color:"white" },
          }}
        > <nav style={{lineHeight:3,fontFamily:"cursive",fontSize:"1.2rem",color:'white'}} onClick={handleDrawerToggle}>
          <ul>
            <li>
              <Link style={{color:"white",textDecoration:"none"}}   to={`/`}>Home</Link>
            </li>
            <li>
            <Link style={{color:"white",textDecoration:"none"}}   to={`/webseries`}>Web Series</Link>
            </li>
            <li>
            <Link style={{color:"white",textDecoration:"none"}}   to={`/anime`}>Anime</Link>

            </li>
            <li>
            <Link style={{color:"white",textDecoration:"none"}}   to={`/bollywoodmovies`}>BollyWood Movies</Link>

            </li>
            <li>
            <Link style={{color:"white",textDecoration:"none"}}  to={`/dualaudio`}>Dual Audio Movies</Link>

            </li>
            <li>

              <Link style={{color:"white",textDecoration:"none"}}  to={`/admin`} >Support</Link>
            </li>
            <li>
              <Link style={{color:"white",textDecoration:"none"}}  to={`/about`}>About</Link>

            </li>
          </ul>
        </nav>
          {/* {drawer} */}
      <h1>{`Ⓒ  ${year}`}</h1>
        </Drawer>
      </Box>
    </Box>
  );
}
