import React, { useEffect } from "react";
import { useParams } from "react-router-dom"
import axios from "axios";
import { Box, Grid,CircularProgress } from "@mui/material";
import Card from "./card/card";
import moment from "moment";
import { Helmet } from "react-helmet";
export default function Category() {
    const url = `https://fourkhdhub.onrender.com/category`;

    const { category } = useParams();
    const [get, setget] = React.useState([]);
    const [render, setrender] = React.useState(1);


    useEffect(() => { userget() }, [category])
    async function myquery() {
        try {
            await axios.get(url).then((res) => {
                console.log(res.data);
                setget(res.data);
                setrender(0)
            })
        } catch (error) {
            console.log(error);
        }

    };


    async function userget() {
        try {
            await axios.post(url, { category }).then((res) => { myquery(); console.log('posted' + res) });
        } catch (err) {
            console.log(err);
        }
    };




    return <><Box>{!render?<Grid container spacing={2}>
            {get.map((data, i) => {
                return <Grid item xs={6} sm={3} key={i}>
                <Helmet>
        <title>{data.tags} 4khdhub </title>
        <meta name="description" content="Category page of 4khdhub" />
        <meta name="keywords" content="hd Movies download | 4khdhub movies | bollywood movies download " />
    </Helmet>
                    <Card date={moment(data.createdAt).fromNow()} s1={data.sam1} s2={data.sam2} s3={data.sam3} s4={data.sam4} dw={data.Download} imagelogo={data.imagelogo} moviename={data.moviename} discription={data.discription} tags={data.tags} key={i} />
                </Grid>
            })} 
        </Grid>:<CircularProgress style={{position:"relative",left:"45%",marginTop:"25%"}} />}
    </Box>
    </>
};