import React from "react";


export default function Admin(){
    return <div style={{display:"-ms-flexbox",color:"white",fontFamily:"cursive"}}>
    <h1>Support Page</h1>
    <ul>
        <li>
            Feel Free To Req Any Movie & Series .
        </li>
        <li> Any Type Of query and Support Click mail icon</li>
    </ul>
  <a href=" mailto:racreation.ra@gmail.com"><img style={{width:"300px"}} src="https://1000logos.net/wp-content/uploads/2021/05/Gmail-logo.png"/></a>
    </div>
}